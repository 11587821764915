var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-footer" }, [
    _c("div", { staticClass: "footer-row" }, [
      _c("div", { staticClass: "footer-left" }, [
        _c("div", { staticClass: "customer" }, [
          _c("div", { staticClass: "customer-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("footer.customerService")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "customer-list" }, [
            _c(
              "a",
              {
                staticClass: "customer-item",
                attrs: { href: "mailto:service_ph@mabiliscash.ph" },
              },
              [
                _c("img", { attrs: { src: require("./img/email.svg") } }),
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("footer.email")) +
                    "\n                    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "customer-item",
                attrs: { href: "tel: 0063-322306046" },
              },
              [
                _c("img", { attrs: { src: require("./img/tel.svg") } }),
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("footer.tel")) +
                    "\n                    "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "customer-item" }, [
              _c("img", { attrs: { src: require("./img/clock.svg") } }),
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.$t("footer.time")) +
                  "\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "customer-title address-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("footer.address")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "customer-list" }, [
            _c("div", { staticClass: "customer-item" }, [
              _c("img", { attrs: { src: require("./img/address.svg") } }),
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.$t("footer.addressDetail")) +
                  "\n                    "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-right" }, [
        _c("div", { staticClass: "follow-us-title d-block d-md-none" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("footer.follow")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "follow-us-icon-wrap" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("./img/facebook.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.jump(1)
              },
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("./img/ins.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.jump(2)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "download-app" },
          [
            _c("app-store-button", {
              attrs: { type: "google", theme: "dark" },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "copy-right-wrap" }, [
      _c("div", { staticClass: "copy-right" }, [
        _c(
          "div",
          { staticClass: "privacy", on: { click: _vm.handleClickPrivacy } },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("footer.privacy")) +
                "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("footer.copyRight")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("footer.registerInfo")) +
              "\n            "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }