<!-- @Author: xiaoyaqu -->
<!-- @Date: 2019-08-26 15:13:05.453 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-27 11:43:13 -->

<template>
    <div class="pa-footer">
        <div class="footer-row">
            <div class="footer-left">
                <div class="customer">
                    <div class="customer-title">
                        {{ $t('footer.customerService') }}
                    </div>
                    <div class="customer-list">
                        <a
                            class="customer-item"
                            href="mailto:service_ph@mabiliscash.ph"
                        >
                            <img src="./img/email.svg">
                            {{ $t('footer.email') }}
                        </a>
                        <a
                            class="customer-item"
                            href="tel: 0063-322306046"
                        >
                            <img src="./img/tel.svg">
                            {{ $t('footer.tel') }}
                        </a>
                        <div class="customer-item">
                            <img src="./img/clock.svg">
                            {{ $t('footer.time') }}
                        </div>
                    </div>
                    <div class="customer-title address-title">
                        {{ $t('footer.address') }}
                    </div>
                    <div class="customer-list">
                        <div class="customer-item">
                            <img src="./img/address.svg">
                            {{ $t('footer.addressDetail') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-right">
                <div class="follow-us-title d-block d-md-none">
                    {{ $t('footer.follow') }}
                </div>
                <div class="follow-us-icon-wrap">
                    <img
                        class="icon"
                        src="./img/facebook.png"
                        alt=""
                        @click="jump(1)"
                    >
                    <img
                        class="icon"
                        src="./img/ins.png"
                        alt=""
                        @click="jump(2)"
                    >
                </div>
                <div class="download-app">
                    <!-- <app-store-button
                        type="apple"
                        theme="light"
                    /> -->
                    <app-store-button
                        type="google"
                        theme="dark"
                    />
                </div>
            </div>
        </div>
        <div class="copy-right-wrap">
            <div class="copy-right">
                <div
                    class="privacy"
                    @click="handleClickPrivacy"
                >
                    {{ $t('footer.privacy') }}
                </div>
                <div class="info">
                    {{ $t('footer.copyRight') }}
                </div>
                <div>
                    {{ $t('footer.registerInfo') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import webviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

import AppStoreButton from 'mabilis-cash/common/component/app-store-button';
import {downloadUrl} from 'mabilis-cash/common/constant/config';

export default {
    name: 'PaFooter',

    components: {
        AppStoreButton
    },

    methods: {
        downLoadApp() {
            window.open(downloadUrl);
        },

        jump(type) {
            if (type === 1) {
                webviewInterface.openBrowser('https://www.facebook.com/people/Mabiliscash/100091254841683/');
            } else {
                webviewInterface.openBrowser('https://www.instagram.com/mabilis_cashofficial/');
            }
        },

        handleClickPrivacy() {
            webviewInterface.openBrowser('https://www.mabiliscash.ph/webview/static-agreement/MABILIS_CASH_PRIVACY');
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "~mabilis-cash/common/style/index";

.pa-footer {
    position: relative;
    background-color: #151245;
    font-family: Mulish;
    color: #fff;
    z-index: 5;
    border-radius: 80px 80px 0 0;
    margin-top: -80px;

    @media screen and (max-width: $screen-md) {
        border-radius: 40px 40px 0 0;
        margin-top: -40px;
    }

    .footer-row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        max-width: 1440px;
        margin: 0 auto;
        padding: 100px 120px 50px;

        @media screen and (max-width: $screen-md) {
            flex-direction: column;
            align-items: flex-start;
            padding: 40px 20px 30px;
        }
    }

    .customer {
        color: #fff;

        &-title {
            font-size: 18px;
            font-weight: 800;
            line-height: 18px;
            margin-bottom: 10px;
        }

        &-list {
            display: flex;
            align-items: center;

            .customer-item {
                display: flex;
                align-items: center;
                margin-right: 50px;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                }

                font-size: 12px;
                font-weight: 300;
                line-height: 12px;
            }

            @media screen and (max-width: $screen-md) {
                flex-direction: column;
                align-items: flex-start;

                .customer-item {
                    margin-right: 0;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        a {
            color: #fff;
        }

        .address-title {
            margin-top: 50px;

            @media screen and (max-width: $screen-md) {
                margin-top: 30px;
            }
        }
    }

    .follow-us-title {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
    }

    .follow-us-icon-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 66px;

        .icon {
            width: 24px;
            height: 24px;
            margin-left: 20px;
            cursor: pointer;
        }

        @media screen and (max-width: $screen-md) {
            justify-content: flex-start;
            margin-bottom: 30px;

            .icon {
                width: 20px;
                height: 20px;
                margin-left: 0;
                margin-right: 10px;
            }
        }
    }

    .download-app {
        display: flex;

        @media screen and (max-width: $screen-md) {
            ::v-deep .app-store-button {
                width: 50%;
                margin-right: 5px;

                .channel-btn {
                    width: 100%;
                    height: auto;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .copy-right-wrap {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 120px 50px;

        @media screen and (max-width: $screen-md) {
            padding: 0 20px 30px;
        }

        .copy-right {
            border-top: 0.5px solid rgba(255, 255, 255, 0.5);
            text-align: center;
            font-size: 10px;
            font-weight: 300;
            line-height: 15px;
            padding-top: 50px;

            .privacy {
                cursor: pointer;
                width: fit-content;
                margin: 0 auto;
                border-bottom: 0.5px solid #fff;
                line-height: 1;
            }

            .info {
                margin: 5px 0;
            }

            @media screen and (max-width: $screen-md) {
                font-size: 9px;
                padding-top: 30px;

                .info {
                    margin-top: 5px;
                }
            }
        }
    }
}

</style>
